<template>
    <div class="system-roles list-page page">
        <div class="page--list">
            <div class="scroll-wrapper pd4 set--bottom-foot-padding">
                <!-- <list-tool-bar>
                    <el-button type="primary"  style="margin-bottom:15px;" v-if="auth.eidtEmp" @click.stop="handleEditEmpDia">批量修改对接员工</el-button>
                </list-tool-bar> -->
                <el-row
                    style="flex-wrap: wrap; padding: 16px 16px 0 16px; background: #f4f7fa; margin-bottom: 15px"
                    class="flex"
                >
                    <el-col :span="6" style="margin-bottom: 16px">
                        <div>
                            <el-input placeholder="请输入内容" v-model.trim="search_value" class="input-with-select">
                                <el-select
                                    style="width: 120px"
                                    v-model="search_type"
                                    slot="prepend"
                                    placeholder="请选择"
                                >
                                    <el-option
                                        v-for="item in searchTypeEnum"
                                        :key="item.value"
                                        :label="item.name"
                                        :value="item.value"
                                    ></el-option>
                                </el-select>
                            </el-input>
                        </div>
                    </el-col>
                    <el-col :span="6" style="margin-left: 24px; margin-bottom: 16px">
                        <div style="display: flex; align-items: center">
                            <div style="flex-shrink: 0">协议名称：</div>
                            <el-select v-model="search_name" style="flex: 1">
                                <el-option
                                    v-for="e in treatyList"
                                    :key="e.id"
                                    :label="e.name"
                                    :value="e.id"
                                ></el-option>
                            </el-select>
                        </div>
                    </el-col>
                    <el-button style="margin-left: 24px; margin-bottom: 16px" type="primary" @click.stop="handleSubmit"
                        >搜索</el-button
                    >
                    <el-button @click.stop="handleClear">清空</el-button>
                </el-row>
                <el-table :data="tableData" @selection-change="handleSelectionChange">
                    <!-- <el-table-column type="selection" width="55">
                    </el-table-column> -->
                    <el-table-column label="渠道编号" prop="chanCode"></el-table-column>
                    <el-table-column label="渠道区域" prop="regionName"></el-table-column>
                    <el-table-column label="渠道名称" prop="chanName" min-width="160"></el-table-column>

                    <el-table-column label="类型" prop="typeName"></el-table-column>
                    <el-table-column label="渠道协议名称" prop="treatyName"></el-table-column>
                    <el-table-column label="协议生效时间" prop="starttimeName">
                        <template slot-scope="scope">
                            <div v-html="scope.row.starttimeName"></div>
                        </template>
                    </el-table-column>
                    <el-table-column label="协议到期时间" prop="endtimeName">
                        <template slot-scope="scope">
                            <div v-html="scope.row.endtimeName"></div>
                        </template>
                    </el-table-column>
                    <el-table-column label="协议签约员工" prop="signEmpName"> </el-table-column>
                    <el-table-column label="渠道商是否签字" prop="signStateName"> </el-table-column>

                    <el-table-column label="操作" fixed="right" min-width="120">
                        <template slot-scope="scope">
                            <div class="operator-cell">
                                <el-button v-if="true || auth.save" type="text" @click.stop="handleDetail(scope.row)"
                                    >去盖章</el-button
                                >
                                <!-- <el-button
                                    v-if="true||auth.delete"
                                    type="text"
                                    @click.stop="handleDelete(scope.row)"
                                >删除</el-button> -->
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
            </div>

            <list-foot-bar>
                <el-pagination
                    layout="total, sizes, prev, pager, next, jumper"
                    :current-page.sync="pageNum"
                    :page-size="pageSize"
                    :total="total"
                    @size-change="handlePageSizeChange"
                    @current-change="handlePageNoChange"
                ></el-pagination>
            </list-foot-bar>
        </div>

        <!-- 批量修改对接员工 -->
        <el-dialog
            class="editEmpDia"
            :visible="showEditEmpDia"
            @close="cancelEditEmpDiaTop"
            title="修改渠道商对接员工"
            width="560px"
        >
            <el-form
                :model="editEmpInfo"
                :rules="editEmpInforules"
                label-width="140px"
                label-suffix=":"
                ref="editEmpInfo"
                style="width: 100%"
                @submit.prevent="seveEditEmpDia"
            >
                <el-form-item label="员工存在系统" prop="sys">
                    <el-radio-group v-model="editEmpInfo.sys">
                        <el-radio label="CRM">房产CRM</el-radio>
                        <el-radio label="AYM">移民CRM</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="对接员工" prop="empid">
                    <template>
                        <el-select
                            class="empidselect"
                            v-model="editEmpInfo.empid"
                            filterable
                            remote
                            reserve-keyword
                            placeholder="请输入"
                            :remote-method="remoteMethod"
                            :loading="loading"
                        >
                            <el-option
                                v-for="item in empOptions"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                            >
                            </el-option>
                        </el-select>
                    </template>
                </el-form-item>

                <!-- <el-form-item label="绩效工资核算月份" prop="grantTime1" style="margin-bottom:24px;">
                    <el-date-picker  :type="datetype"  style="width:380px;" v-model.trim="allGrant.grantTime1" :value-format="valueformat" placeholder="请选择"></el-date-picker>
            </el-form-item>
            <el-form-item label="绩效工资发放月份" prop="grantTime2" style="margin-bottom:24px;">
                    <el-date-picker  :type="datetype"  style="width:380px;" v-model.trim="allGrant.grantTime2" :value-format="valueformat" placeholder="请选择"></el-date-picker>
            </el-form-item> -->
            </el-form>
            <div slot="footer">
                <el-button @click="cancelEditEmpDia">取消</el-button>
                <el-button @click="seveEditEmpDia" type="primary">保存</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import { base64encode, utf16to8, utf8to16, base64decode } from '../../utils/base64';

    export default {
        components: {},
        props: {},
        data() {
            return {
                treatyList: [],
                tableData: [],
                search_value: '',
                search_name: '',
                search_type: 1,
                pageNum: 1,
                pageSize: 10,
                total: 0,

                searchTypeEnum: [
                    { name: '渠道名称', value: 1 },
                    { name: '渠道电话', value: 2 },
                    { name: '协议签约员工', value: 3 },
                ],
                auth: {
                    delete: false,
                    eidtEmp: false,
                },
                showEditEmpDia: false,
                editEmpInfo: {
                    sys: '',
                    empid: '',
                },
                editEmpInforules: {
                    sys: { required: true, message: '请选择员工存在系统', trigger: ['change', 'blur'] },
                    //  empid      : { required: true, message: "请选择对接员工",trigger:['change', 'blur'] }
                },
                empOptions: [],
                multipleSelection: '',
                loading: false,
                urlQuery: '',
                sysCode: '', //AYM CEN
            };
        },
        created() {
            this.urlQuery = this.getUrlQuery();
            this.sysCode = this.urlQuery.sysCode;

            this.auth.delete = this.$hasAuthFor('api/channel/deleteChannel'); //删除
            this.auth.eidtEmp = this.$hasAuthFor('api/common/channel/updateChannelConnEmp'); //批量修改对接员工
            this.fetchTreatyList();
            this.fetchData();
        },
        mounted() {},
        watch: {},
        computed: {},
        methods: {
            getUrlQuery() {
                // 本地调试注意域名要一样
                // let url = 'http://112.74.129.135:8080/center//#/customer/stampList?c3lzQ29kZT1BWU0='; //本地调试的时候需要在移民跳转复制一个地址来

                let url = window.location.href;
                let urlStrDe = url.split('?')[1];
                let urlStr = base64decode(urlStrDe);
                // 创建空对象存储参数
                let obj = {};
                // 再通过 & 将每一个参数单独分割出来
                let paramsArr = urlStr.split('&');
                for (let i = 0, len = paramsArr.length; i < len; i++) {
                    // 再通过 = 将每一个参数分割为 key:value 的形式
                    let arr = paramsArr[i].split('=');
                    obj[arr[0]] = arr[1];
                }
                return obj;
            },
            async fetchTreatyList() {
                const res = await this.$axios({
                    url: '/api/channel/queryTreatyAllList',
                    method: 'post',
                });
                if (res.code === 2000) {
                    this.treatyList = res.data.treatyList || [];
                } else {
                    this.$message({
                        message: res.msg || '获取数据失败',
                        type: 'warning',
                    });
                }
            },

            // 搜索对接员工
            async remoteMethod(query) {
                console.log(query);
                if (query !== '') {
                    this.loading = true;
                    const res = await this.$axios({
                        url: '/api/employee/queryEmployeeList',
                        method: 'post',
                        data: {
                            searchName: query,
                        },
                    });
                    if (res.code === 2000) {
                        this.loading = false;
                        res.data.empList.map(item => {
                            item.label = item.name + '--' + item.usercode + '--' + item.phone;
                            item.value = item.usercode;
                        });
                        this.empOptions = res.data.empList;
                    }
                } else {
                    this.empOptions = [];
                }
            },
            cancelEditEmpDiaTop() {
                if (this.showEditEmpDia) {
                    this.cancelEditEmpDia();
                }
            },
            cancelEditEmpDia() {
                this.editEmpInfo.sys = '';
                this.editEmpInfo.empid = '';
                this.showEditEmpDia = false;
                setTimeout(() => {
                    this.$refs.editEmpInfo.clearValidate();
                }, 20);
            },

            handleEditEmpDia() {
                if (this.multipleSelection.length == 0) {
                    this.$message({
                        message: '请选择需要修改的数据',
                        type: 'warning',
                    });
                    return;
                }

                this.showEditEmpDia = true;
            },
            handleSelectionChange(val) {
                this.multipleSelection = val;
            },
            async seveEditEmpDia() {
                let loadingRef;
                try {
                    await this.$refs.editEmpInfo.validate();
                    loadingRef = this.$loading({
                        target: '.editEmpDia .el-dialog',
                    });

                    let codearr = [];
                    this.multipleSelection.forEach(item => {
                        codearr.push(item.channelCode);
                    });
                    const res = await this.$axios({
                        url: '/api/common/channel/updateChannelConnEmp',
                        method: 'post',
                        data: {
                            channelCodes: codearr.join(),
                            empCode: this.editEmpInfo.empid,
                            sysCode: this.editEmpInfo.sys,
                        },
                    });
                    if (res.code === 2000) {
                        this.$message({
                            message: '保存成功',
                            type: 'success',
                        });
                        loadingRef && loadingRef.close();
                        this.cancelEditEmpDia();
                        this.fetchData();
                    } else if (res.code !== 1003) throw res;
                } catch (reason) {
                    reason &&
                        this.$message({
                            message: reason.msg || '保存失败',
                            type: 'error',
                        });
                    loadingRef && loadingRef.close();
                    console.warn(reason);
                }
            },

            // 详情
            handleDetail(data) {
                const { href } = this.$router.resolve({
                    name: 'PreviewPdf', //这里是跳转页面的name
                    query: {
                        //要传的参数
                        id: data.id,
                        sysCode: this.sysCode,
                    },
                });
                window.open(href, '_blank'); //打开新的窗口

                // 本地调试的时候需要写死参数好调试
                // const { href } = this.$router.resolve({
                //     name: 'PreviewPdf', //这里是跳转页面的name
                //     query: {
                //         //要传的参数
                //         id: 613,
                //         sysCode: 'AYM',
                //     },
                // });

                // window.open(href, '_blank'); //打开新的窗口
                // this.$router.push({
                //     path: `/customer/channelPhoneList/previewPdf`,
                //     query: {
                //         id: 613,
                //         sysCode: 'AYM',
                //     },
                // });
                // 渠道详情
                // this.$router.push({
                // 	path: `/customer/channelPhoneList/Detail`,
                // 	query: {id: data.id,channelCode:data.channelCode}
                // });
                // 渠道新增 编辑
                // this.$router.push({
                // 	path: `/customer/channelPhoneList/add`,
                // 	query: {id: data.id}
                // });
                // 在线签署渠道协议列表
                // this.$router.push({
                // 	path: `/customer/channelLineList`,
                // 	query: {id: data.id}
                // });
            },
            // 点击表格行操作按钮: 删除
            async handleDelete(data) {
                let loadingRef;
                try {
                    await this.$confirm(
                        '请先确定该渠道在各业务系统中是否关联客户，删除后会同步删除业务系统中的渠道，您确定要删除么？',
                        '删除渠道提示信息',
                    );
                    loadingRef = this.$loading();
                    const res = await this.$axios({
                        url: '/api/channel/deleteChannel',
                        method: 'post',
                        data: {
                            id: parseInt(data.id),
                        },
                    });
                    if (res.code === 2000) {
                        this.$message({
                            message: '删除成功',
                            type: 'success',
                        });
                        loadingRef && loadingRef.close();
                        this.fetchData();
                    } else if (res.code !== 1003) throw res;
                } catch (reason) {
                    if (reason !== 'cancel') {
                        this.$message({
                            message: reason.msg || '删除失败',
                            type: 'warning',
                        });
                    }
                    loadingRef && loadingRef.close();
                }
            },
            handleSubmit() {
                this.pageNum = 1;
                this.pageSize = 10;
                this.fetchData();
            },
            handleClear() {
                this.pageNum = 1;
                this.pageSize = 10;
                this.search_value = '';
                this.search_name = '';
                this.search_type = 1;
                this.fetchData();
            },
            async fetchData() {
                console.log(123);
                const loading = this.$loading();
                const res = await this.$axios({
                    // url: "/api/channel/queryChannelPhoneList",
                    url: '/api/channel/queryStampList',
                    method: 'post',
                    data: {
                        searchSysCode: this.sysCode,
                        pageNum: this.pageNum,
                        pageSize: this.pageSize,
                        searchType: this.search_type,
                        searchValue: this.search_value,
                        searchTreatyId: this.search_name,
                    },
                });
                loading.close();
                if (res.code === 2000) {
                    this.tableData = res.data.stampList || [];
                    this.total = res.data.count || 0;
                } else {
                    this.$message({
                        message: res.msg || '获取数据失败',
                        type: 'warning',
                    });
                }
            },
            // 翻页相关
            handlePageSizeChange(size) {
                this.pageSize = size;
                this.fetchData();
            },
            // 翻页相关
            handlePageNoChange(no) {
                this.pageNum = no;
                this.fetchData();
            },
        },
    };
</script>
<style lang="scss" scoped>
    .empidselect {
        width: 340px;
    }
</style>
