<template>
    <div class="system-roles list-page page" :class="sysCode ? 'syspage' : ''">
        <div class="page--list" style="background: #fff">
            <div class="scroll-wrapper pd4 set--bottom-foot-padding">
                <div class="header flex f16 a-center pr16" v-if="sysCode">
                    <div class="fb f16">当前位置：</div>
                    <div class="color909399">在线签署协议列表</div>
                </div>
                <list-tool-bar>
                    <el-button type="primary" style="margin-bottom: 15px" v-if="auth.save" @click.stop="handleAddBtn"
                        >撤销合同</el-button
                    >
                </list-tool-bar>
                <el-row style="flex-wrap: wrap; padding: 16px 16px 0 16px; background: #f4f7fa" class="flex">
                    <div style="margin-right: 32px; margin-bottom: 16px">
                        <el-input
                            placeholder="请输入内容"
                            maxlength="30"
                            v-model.trim="search_value"
                            class="input-with-select"
                        >
                            <el-select style="width: 120px" v-model="search_type" slot="prepend" placeholder="请选择">
                                <el-option
                                    v-for="item in searchTypeEnum"
                                    :key="item.value"
                                    :label="item.name"
                                    :value="item.value"
                                ></el-option>
                            </el-select>
                        </el-input>
                    </div>
                    <div style="margin-bottom: 16px">
                        <el-select
                            class="width1h"
                            style="width: 120px; margin-right: 8px"
                            v-model="search_type2"
                            slot="prepend"
                            placeholder="请选择"
                        >
                            <el-option
                                v-for="item in searchTypeEnum2"
                                :key="item.value"
                                :label="item.name"
                                :value="item.value"
                            ></el-option>
                        </el-select>
                        <el-date-picker
                            class="width2"
                            v-model="searchStartTime"
                            value-format="yyyy-MM-dd"
                            placeholder="请选择"
                        ></el-date-picker>
                        <span> - </span>
                        <el-date-picker
                            class="width1h"
                            v-model="searchEndTime"
                            value-format="yyyy-MM-dd"
                            placeholder="请选择"
                        ></el-date-picker>
                    </div>
                    <div style="display: flex; align-items: center; margin-right: 32px; margin-bottom: 16px">
                        <div style="width: 120px; text-align: right; color: #606266; padding-right: 12px">合同状态</div>
                        <el-select v-model="search_type3" placeholder="请选择" style="width: 202px">
                            <el-option
                                v-for="item in searchTypeEnum3"
                                :key="item.value"
                                :label="item.name"
                                :value="item.value"
                            ></el-option>
                        </el-select>
                    </div>
                    <div style="margin-bottom: 16px">
                        <el-button style="" type="primary" @click.stop="handleSubmit">搜索</el-button>
                        <el-button @click.stop="handleClear">清空</el-button>
                    </div>
                </el-row>

                <el-table :data="tableData" @selection-change="handleSelectionChange">
                    <el-table-column type="selection" width="55"> </el-table-column>
                    <el-table-column label="区域" prop="regionName" width="80"></el-table-column>
                    <el-table-column label="渠道编号" prop="chanCode"></el-table-column>
                    <el-table-column label="渠道名称" prop="chanName"></el-table-column>
                    <el-table-column label="合同编号" prop="contractNo" width="280"></el-table-column>
                    <el-table-column label="协议类型" prop="chagreName"> </el-table-column>
                    <el-table-column label="合同" prop="idcard" min-width="60">
                        <template slot-scope="scope">
                            <div
                                v-show="scope.row.path && scope.row.candown"
                                @click="download(scope.row.path)"
                                style="color: #2878ff; cursor: pointer"
                            >
                                附件
                            </div>
                            <div v-show="scope.row.path && !scope.row.candown" style="color: #ccc">附件</div>
                        </template>
                    </el-table-column>
                    <el-table-column label="合同状态" prop="stateName"> </el-table-column>
                    <el-table-column label="申请人" prop="createEmpName"> </el-table-column>
                    <el-table-column label="申请时间" prop="createTimeName">
                        <template slot-scope="scope">
                            <div v-html="scope.row.createTimeName"></div>
                        </template>
                    </el-table-column>
                    <!-- <el-table-column label="操作"
                        fixed="right">
                        <template slot-scope="scope">
                            <div class="operator-cell">
                                <el-button
                                    v-if="true||auth.save"
                                    type="text"
                                    @click.stop="handleDetail(scope.row)"
                                >详情</el-button>
                             
                                <el-button
                                    v-if="true||auth.delete"
                                    type="text"
                                    @click.stop="handleDelete(scope.row)"
                                >删除</el-button>
                            </div>
                        </template>
                    </el-table-column> -->
                </el-table>
            </div>

            <list-foot-bar>
                <el-pagination
                    layout="total, sizes, prev, pager, next, jumper"
                    :current-page.sync="pageNum"
                    :page-size="pageSize"
                    :total="total"
                    @size-change="handlePageSizeChange"
                    @current-change="handlePageNoChange"
                ></el-pagination>
            </list-foot-bar>
        </div>
    </div>
</template>

<script>
    export default {
        components: {},
        props: {},
        data() {
            return {
                tableData: [],
                search_value: '',
                search_type: 1,
                search_type2: 1,
                search_type3: -1,
                pageNum: 1,
                pageSize: 10,
                total: 0,
                searchStartTime: '',
                searchEndTime: '',

                searchTypeEnum: [
                    { name: '渠道名称', value: 1 },
                    { name: '合同编号', value: 2 },
                    { name: '申请人', value: 3 },
                    { name: '渠道编号', value: 4 },
                ],
                searchTypeEnum2: [{ name: '申请时间', value: 1 }],
                searchTypeEnum3: [
                    { name: '请选择', value: -1 },
                    { name: '签署中', value: 1 },
                    { name: '已完成', value: 2 },
                    { name: '已撤销', value: 3 },
                    { name: '已失效', value: 4 },
                ],
                multipleSelection: '',
                sysCode: '',
                auth: {
                    save: false,
                },
            };
        },
        created() {
            let url = window.location.href;
            console.log(url);
            console.log(this.$route);
            if (this.$route.query.sysCode) {
                this.sysCode = this.$route.query.sysCode;
            } else {
                this.sysCode = '';
                let urlQuery = this.getUrlQuery();
                if (urlQuery.sysCode) {
                    this.sysCode = urlQuery.sysCode;
                    this.$store.commit('setShowMenu', { showMenu: false }); //业务系统来的隐藏菜单
                } else {
                    this.sysCode = '';
                    this.$store.commit('setShowMenu', { showMenu: true }); //显示菜单
                }
            }
            this.auth.save = this.$hasAuthFor('api/channelContract/cacenlChannelContract'); //

            this.fetchData();
            this.getConfigByType();
        },
        mounted() {},
        watch: {},
        computed: {},
        methods: {
            // 获取url参数
            getUrlQuery() {
                // CH-AYM29
                // let  url=  'http://192.168.110.136:8020/#/customer/channelPhoneList/add?id=16&chanCode=CH-AYM29&sysCode=AYM'
                let url = window.location.href;
                if (url.indexOf('?') != -1) {
                    let urlStr = url.split('?')[1];
                    // 创建空对象存储参数
                    let obj = {};
                    // 再通过 & 将每一个参数单独分割出来
                    let paramsArr = urlStr.split('&');
                    for (let i = 0, len = paramsArr.length; i < len; i++) {
                        // 再通过 = 将每一个参数分割为 key:value 的形式
                        let arr = paramsArr[i].split('=');
                        obj[arr[0]] = arr[1];
                    }
                    return obj;
                } else {
                    return {};
                }
            },
            // 获取查群参数
            async getConfigByType() {
                const res = await this.$axios({
                    url: '/api/channelContract/listParam',
                    method: 'post',
                    data: {
                        type: 2, //字典类型 1协议 2行业
                        pid: 0, //父级id
                    },
                });
                if (res.code === 2000) {
                    //
                    //  [
                    //     { name: "请选择", value: -1 },
                    //     { name: "签署中", value: 1 },
                    //     { name: "已完成", value: 2 },
                    //     { name: "已撤销", value: 3 },
                    //     { name: "已失效", value: 4 },

                    // ] ||
                    //
                    this.searchTypeEnum3 = res.data.ChannelContractStateEnum || [];
                }
            },

            async handleAddBtn(data) {
                // multipleSelection
                if (this.multipleSelection.length == 0) {
                    this.$message({
                        message: '请选择需要撤销的合同',
                        type: 'warning',
                    });
                    return;
                }
                let _has = this.multipleSelection.findIndex(element => {
                    return element.stateName !== '签署中';
                });
                console.log(_has);
                if (_has >= 0) {
                    this.$message({
                        message: '仅可撤销签署中的合同',
                        type: 'warning',
                    });
                    return;
                }

                let idarr = [];
                this.multipleSelection.forEach(item => {
                    idarr.push(item.id);
                });

                let loadingRef;
                try {
                    await this.$confirm('确定要撤销合同吗', '系统提示');
                    loadingRef = this.$loading();
                    const res = await this.$axios({
                        url: '/api/channelContract/cacenlChannelContract',
                        method: 'post',
                        data: {
                            ids: idarr.join(),
                        },
                    });
                    if (res.code === 2000) {
                        this.$message({
                            message: '删除成功',
                            type: 'success',
                        });
                        loadingRef && loadingRef.close();
                        this.fetchData();
                    } else if (res.code !== 1003) throw res;
                } catch (reason) {
                    if (reason !== 'cancel') {
                        this.$message({
                            message: reason.msg || '删除失败',
                            type: 'warning',
                        });
                    }
                    loadingRef && loadingRef.close();
                }
            },
            handleSelectionChange(val) {
                this.multipleSelection = val;
            },
            download(path) {
                let headurl = '';
                if (path.length > 4) {
                    headurl = path.slice(0, 4);
                }
                if (headurl == 'http') {
                    window.open(path, 'download');
                } else {
                    window.open(this.$baseURL + path, 'download');
                }
            },
            // 详情
            handleDetail(data) {
                // this.$router.push({
                // 	path: `/customer/channelPhoneList/Detail`,
                // 	query: {id: data.id}
                // });
                this.$router.push({
                    path: `/customer/channelPhoneList/add`,
                    query: { id: data.id },
                });
            },
            // 点击表格行操作按钮: 删除
            async handleDelete(data) {
                let loadingRef;
                try {
                    await this.$confirm('确定要删除吗', '系统提示');
                    loadingRef = this.$loading();
                    const res = await this.$axios({
                        url: '/api/resource/deleteResource1',
                        method: 'post',
                        data: {
                            resourceid: parseInt(data.id),
                        },
                    });
                    if (res.code === 2000) {
                        this.$message({
                            message: '删除成功',
                            type: 'success',
                        });
                        loadingRef && loadingRef.close();
                        this.fetchData();
                    } else if (res.code !== 1003) throw res;
                } catch (reason) {
                    if (reason !== 'cancel') {
                        this.$message({
                            message: reason.msg || '删除失败',
                            type: 'warning',
                        });
                    }
                    loadingRef && loadingRef.close();
                }
            },
            handleSubmit() {
                this.pageNum = 1;
                this.pageSize = 10;
                this.fetchData();
            },
            handleClear() {
                this.pageNum = 1;
                this.pageSize = 10;
                this.search_value = '';
                this.search_type = 1;
                this.search_type3 = '';
                this.searchStartTime = '';
                this.searchEndTime = '';
                this.fetchData();
            },
            async fetchData() {
                const loading = this.$loading();
                const res = await this.$axios({
                    // url: "/api/channel/queryChannelPhoneList",
                    url: '/api/channelContract/queryChannelContractList',
                    method: 'post',
                    data: {
                        pageNum: this.pageNum,
                        pageSize: this.pageSize,
                        search_type: this.search_type,
                        search_value: this.search_value,
                        query_staDate: this.searchStartTime,
                        query_endDate: this.searchEndTime,
                        search_state: this.search_type3,
                        sysCode: this.sysCode,
                    },
                });
                loading.close();
                if (res.code === 2000) {
                    this.tableData = res.data.channelContractList || [];
                    this.tableData.forEach(element => {
                        if (this.userInfo.employeeId == element.createEmp) {
                            element.candown = true;
                        } else {
                            element.candown = false;
                        }
                    });
                    this.total = res.data.count || 0;
                } else {
                    this.$message({
                        message: res.msg || '获取数据失败',
                        type: 'warning',
                    });
                }
            },
            // 翻页相关
            handlePageSizeChange(size) {
                this.pageSize = size;
                this.fetchData();
            },
            // 翻页相关
            handlePageNoChange(no) {
                this.pageNum = no;
                this.fetchData();
            },
        },
    };
</script>
<style lang="scss" scoped>
    // .page {
    //     height: 100%;
    //     padding: 80px;
    //     background: #f9f9f9;
    //     margin: 0;
    // }
    .syspage {
        height: 100%;
        padding: 80px;
        background: #f9f9f9;
        margin: 0;
    }
    @media (max-width: 1920px) {
        .syspage {
            padding: 80px;
        }
    }
    // 小于1600时
    @media (max-width: 1600px) {
        .syspage {
            padding: 40px;
        }
    }
    .header {
        height: 40px;
    }
    // 公用
    .pl16 {
        padding-left: 16px;
    }
    .pr16 {
        padding-right: 16px;
    }
    .pt24 {
        padding-top: 24px;
    }
    .pb24 {
        padding-bottom: 24px;
    }
    .ml12 {
        margin-left: 12px;
    }
    .flex {
        display: flex;
    }
    .flex-column {
        flex-direction: column;
    }
    .flex1 {
        flex: 1;
    }
    .a-center {
        align-items: center;
    }
    .fb {
        font-weight: 600;
    }
    .f16 {
        font-size: 16px;
    }
    .hover {
        cursor: pointer;
    }
    // 公用完
</style>
