import forList from '@/mixin/forList';
export default {
    name: 'channel-customer-list',
    mixins: [forList],
    data() {
        const searchTypeEnum = [
            { label: '手机号码', value: 1 },
            { label: '客户编号', value: 2 },
            { label: '客户姓名', value: 3 },
            { label: '录入人员', value: 4 },
        ];
        const phoneCheck = (rule, value, callback) => {
            if (!value) {
                return callback(new Error('请输入手机号'));
            }
            const regex = /^1[3,4,5,6,7,8,9][0-9]{9}$/;
            if (!regex.test(value)) {
                callback(new Error('请输入正确的手机号'));
            } else {
                callback();
            }
        };
        return {
            searchTypeEnum,
            SP_: {
                search_types: 1, //1-客户电话 2-客户code 3-客户名称 4-录入人员
                searchValue: '',
                dateType: 1, //1-录入时间 2-推荐时间
                starttime: '', //开始时间
                endtime: '', //结束时间
                isSell: null, //推荐状态：1-是 0-否
                isDeal: null, //是否成交：1-是 0-否
                state: null, //状态： 1-成功 2-失败 3-异常
            },
            tableData: [],

            channelEmpInfo: {
                empCode: '',
                empName: '',
            },
            accountForm: {
                name: '',
                phone: '',
                isSell: 0,
                staffName: '',
            },
            rules: {
                name: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
                phone: [
                    { required: true, message: '请输入手机号码', trigger: 'blur' },
                    { validator: phoneCheck, trigger: 'blur' },
                ],
            },
            showAddDia: false,
            uploadHeaders: {
                // 图片上传的请求头
                token: this.$store.state.token,
            },
            uploadForm: {
                fileList: [],
            },
            uploadForm2: {
                isSell: 0,
            },
            showUploadDia: false,
            //
            showrecommendDia: false,
            multipleSelection: [],
            recommendForm: {
                staffName: '',
            },
            showDetailDia: false,
            followlist: [], //跟进详情
        };
    },
    computed: {
        statusOj() {
            return val => {
                let obj = {};
                if (val === '成功') {
                    obj = { type: 'success', text: '成功' };
                } else if (val === '失败') {
                    obj = { type: 'danger', text: '失败' };
                } else if (val === '作废') {
                    obj = { type: 'warning', text: '作废' };
                }
                return obj;
            };
        },
    },
    methods: {
        async fetchData() {
            const loading = this.$loading();
            try {
                const data = {
                    ...this.extractParams(),
                    ...this.SP_,
                };
                const res = await this.$axios({
                    url: '/api/channelCustomer/queryList',
                    method: 'post',
                    data,
                });
                if (res.code === 2000) {
                    const list = res.data.List;
                    this.tableData = list;
                    this.total = res.data.count;
                    loading.close();
                } else if (res.code !== 1003) throw res;
            } catch (reason) {
                console.warn('获取数据失败', reason);
            }
        },
        handleSysChange() {},
        // handleSearch() {
        //     console.log('搜索', this.SP_);
        // },
        handleSubmit() {
            this.pageNum = 1;
            this.pageSize = 10;
            this.fetchData();
        },
        handleClear() {
            this.pageNum = 1;
            this.pageSize = 10;
            this.SP_ = {
                search_types: 1, //1-客户电话 2-客户code 3-客户名称 4-录入人员
                searchValue: '',
                dateType: 1, //1-录入时间 2-推荐时间
                starttime: '', //开始时间
                endtime: '', //结束时间
                isSell: null, //推荐状态：1-是 0-否
                isDeal: null, //是否成交：1-是 0-否
                state: null, //状态： 1-成功 2-失败 3-异常
            };
            this.fetchData();
        },
        fetchChannelEmpInfo() {
            this.$axios({
                url: '/api/channelCustomer/getChannelEmpInfo',
                method: 'post',
            }).then(res => {
                if (res.code === 2000) {
                    this.channelEmpInfo = res.data;
                }
            });
        },
        handleAddBtn() {
            this.showAddDia = true;
            this.$nextTick(() => {
                this.$refs.accountForm.resetFields();
            });
        },
        submitAdd() {
            this.$refs.accountForm.validate(valid => {
                if (valid) {
                    // this.showAddDia = false;
                    // this.$message.success('添加成功');
                    // this.fetchData();

                    this.$axios({
                        url: '/api/channelCustomer/saveOneCust',
                        method: 'post',
                        data: {
                            name: this.accountForm.name,
                            phone: this.accountForm.phone,
                            isSell: this.accountForm.isSell,
                        },
                    }).then(res => {
                        if (res.code == 2000) {
                            this.$message.success('添加成功');
                            this.showAddDia = false;
                            this.fetchData();
                        } else {
                            this.$message.error(res.msg);
                        }
                    });
                } else {
                    return false;
                }
            });
        },

        handleImport() {
            this.showUploadDia = true;
        },
        handleExceed() {
            this.$message({
                message: `最多只能上传1个文件`,
                type: 'error',
            });
        },
        handleRemove(file, fileList) {
            this.uploadForm.fileList = [];
        },
        //
        handleUploadSuccess(res, file) {
            console.log(res);
            console.log(file);
            // this.uploading.close();

            if (res.code == 2000) {
                this.$message({
                    message: '保存成功',
                    type: 'success',
                });
                setTimeout(() => {
                    this.showUploadDia = false;
                    this.fetchData();
                }, 500);
            } else {
                this.$message({
                    message: res.msg,
                    type: 'error',
                });
            }
        },
        handleFileChange(files) {
            this.uploadForm.fileList = [files];
        },
        downLoadfile() {
            this.$axios({
                url: '/api/channelCustomer/downCustTemplate',
                method: 'post',
            }).then(res => {
                if (res.code === 2000) {
                    window.open(this.$baseURL + '/' + res.data.filePath, 'download');
                }
            });
        },
        submitUpload() {
            // this.$refs.uploadForm.validate(valid => {
            //     if (valid) {
            if (this.uploadForm.fileList.length == 0) {
                return this.$message({
                    message: '请选择上传文件',
                    type: 'warning',
                });
            }
            this.$refs.formUpload.submit();
            // this.showAddDia = false;
            // this.$message.success('添加成功');
            // this.fetchData();
            // } else {
            //     return false;
            // }
            // });
        },
        // 批量推荐
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        handleRecommend() {
            if (!this.multipleSelection.length) {
                this.$message({
                    message: '请选择要推荐的用户',
                    type: 'warning',
                });
                return;
            }

            this.showrecommendDia = true;
        },
        submitRecommend() {
            const ids = this.multipleSelection.map(item => item.id);
            this.$axios({
                url: '/api/channelCustomer/batchPushCust',
                method: 'post',
                data: {
                    ids: ids.join(','),
                },
            }).then(res => {
                if (res.code == 2000) {
                    this.$message({
                        message: '推荐成功',
                        type: 'success',
                    });
                    this.showrecommendDia = false;
                    this.fetchData();
                } else {
                    this.$message({
                        message: res.msg,
                        type: 'warning',
                    });
                }
            });
        },
        //
        handleDetail(data) {
            if (!data.custCode) {
                this.$message({
                    message: '该用户暂无推荐码',
                    type: 'warning',
                });
                return;
            }
            this.showDetailDia = true;
            this.$axios({
                url: '/api/channelCustomer/getFollowInfo',
                method: 'post',
                data: {
                    id: data.id,
                },
            }).then(res => {
                if (res.code == 2000) {
                    this.followlist = res.data.followlist;
                } else {
                    this.$message({
                        message: res.msg,
                        type: 'warning',
                    });
                }
            });
        },
    },
    created() {
        this.fetchChannelEmpInfo();
        // console.log('222222222222222', this.$hasAuthFor('/api/channelCustomer/batchPushCust'));
        this.auth.recommend = this.$hasAuthFor('/api/channelCustomer/batchPushCust');
        this.auth.add = this.$hasAuthFor('/api/channelCustomer/saveOneCust');
        this.auth.import = this.$hasAuthFor('/api/channelCustomer/uploadCustFile');
    },
};
