import Vue from 'vue';
import axios from 'axios';
import qs from 'qs';
import store from './store';

// const baseURL = "http://192.168.110.112:7010";            // development 测试 谭睿
// const baseURL = "https://testasp.jindingaus.com";       // development 测试

//   ********  注意看app.js地址改了没 ********
// const baseURL = "http://120.25.85.101:7010";            // development 测试
// const baseURL = "https://center.jindingaus.com/admin";  // production 正式

const baseURLObj = {
    development: 'http://120.25.85.101:7010',
    // development: 'https://center.jindingaus.com/admin',
    testSelf: 'http://120.25.85.101:7010',
    production: 'https://center.jindingaus.com/admin',
};

const baseURL = baseURLObj[process.env.NODE_ENV];
console.log('************baseURL**********', baseURL);

// const ypURL = "http://127.0.0.1:8085/#/login";  // 云盘登陆 本地
// const ypURL = "http://101.35.18.18:8888/forestfilenew/#/login";  // 云盘登陆 测试
const ypURL = 'http://forestfile.jindingaus.com/#/login'; // 云盘登陆 正式

// const ypURL2 = "http://127.0.0.1:8085/#/business_file";  // 云盘不需登录页面 本地
// const ypURL2 = "http://101.35.18.18:8888/forestfilenew/#/business_file";  // 云盘不需登录页面 测试
const ypURL2 = 'http://forestfile.jindingaus.com/#/business_file'; // 云盘不需登录页面 正式

const baseOpts = { baseURL };

function transerPostDataByQs(config) {
    // 将请求体转换为 x-www-form-urlencoded 的 searchParams 格式
    if (config.method === 'post') {
        config.data = qs.stringify(config.data);
    }
    return config;
}

function addTokenHeader(config) {
    // 给 header 里面加一个 token
    config.headers.token = store.state.token || 'fdsjalfsdkf';
    return config;
}

let expireTipsCoolDown = true; // 1003 登录过期的跳转和用户提示，需要做节流处理，防止多个接口同时触发。
function transerResDataToShort(res) {
    // 返回的code如果是1003, 表示登录过期, 需要重新登录
    if (res.data.code == 1003) {
        if (expireTipsCoolDown) {
            store.dispatch('unstoreUser');
            expireTipsCoolDown = false;
            Vue.prototype.$message({
                message: '登录过期，请重新登录',
                type: 'warning',
            });
            Vue.prototype.$loading().close();
            setTimeout(() => {
                expireTipsCoolDown = true;
            }, 3e3); // 节流的频率是3秒
        }
    }
    return res.data;
}

/**
 * $axios 请求体格式为 application/x-www-form-urlencoded
 * $oxios 请求体格式为 application/json
 * axios 好像根据请求体类型自动设置了headers 里面的Content-Type
 * 可能应该显示地设置一下
 */
const $axios = axios.create(baseOpts);
$axios.interceptors.request.use(transerPostDataByQs);
$axios.interceptors.request.use(addTokenHeader);
$axios.interceptors.response.use(transerResDataToShort);

const $oxios = axios.create(baseOpts);
$oxios.interceptors.request.use(addTokenHeader);
$oxios.interceptors.response.use(transerResDataToShort);

/**
 * $axios 为转换请求体为 searchParams 格式
 * $oxios 不会转换，保持 json 字符串格式
 */
Vue.prototype.$axios = $axios.request;
Vue.prototype.$oxios = $oxios.request;
Vue.prototype.$post = $axios.post;
Vue.prototype.$get = $axios.get;
Vue.prototype.$baseURL = baseURL;

Vue.prototype.openSystemURL = async function (opt = {}) {
    const system = opt.system;
    if (!system) return;
    const winOpen = window.open('', '_blank');
    const target = opt.url || 'http://192.168.110.29:8030';
    const empId = store.state.userInfo.employeeId;

    try {
        const res = await $axios.request({
            url: '/api/changeSystem',
            method: 'post',
            data: { empId, system },
        });

        if (res.code === 2000) {
            const tok = encodeURIComponent(store.state.token);
            const oUrl = new URL(target);
            oUrl.searchParams.append('token', tok);
            if (typeof opt.params === 'object')
                for (let key in opt.params) {
                    oUrl.searchParams.append(key, encodeURIComponent(opt.params[key]));
                }
            // window.open(oUrl.href);
            winOpen && (winOpen.location = oUrl.href);
        } else if (res.code === 1003) {
            winOpen && winOpen.close();
        } else throw res;
    } catch (reason) {
        console.warn('登录其他系统失败', reason);
        this.$message({
            message: reason.msg || '获取数据失败',
            type: 'warning',
        });
        winOpen && winOpen.close();
    }
};

export { $axios, $oxios, baseURL, ypURL, ypURL2 };
