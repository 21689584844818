<template>
    <div class="index-wrap page list-page">
        <div class="page--list">
            <div class="scroll-wrapper">
                <el-row class="index-row" :gutter="16">
                    <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                        <div class="item-box">
                            <div class="item-box-title">我的签约信息</div>
                            <div class="item-box-content">
                                <div class="item-list">
                                    <span class="label">签署渠道名称：</span>
                                    <span class="value">{{ chanInfo.name }}</span>
                                </div>
                                <div class="item-list">
                                    <span class="label">渠道联系人：</span>
                                    <span class="value">{{ chanInfo.phone }}</span>
                                </div>
                                <div class="item-list">
                                    <span class="label">渠道联系方式：</span>
                                    <span class="value">{{ chanInfo.phone }}</span>
                                </div>
                                <div class="item-list">
                                    <span class="label">渠道商登录账号：</span>
                                    <span class="value">{{ chanInfo.account }}</span>
                                </div>
                            </div>
                        </div>
                    </el-col>
                    <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                        <div class="item-box">
                            <div class="item-box-title">合作公司信息</div>
                            <div class="item-box-content">
                                <div class="item-list">
                                    <span class="label">合同签署地：</span>
                                    <span class="value">{{ chanInfo.regionName }}</span>
                                </div>
                                <div class="item-list">
                                    <span class="label">我的专属对接人：</span>
                                    <span class="value">{{ chanInfo.empName }}</span>
                                </div>
                                <div class="item-list">
                                    <span class="label">专属对接人联系方式：</span>
                                    <span class="value">{{ chanInfo.empPhone }}</span>
                                </div>
                                <div class="item-list">
                                    <span class="label">渠道协议到期日期：</span>
                                    <span class="value">{{ chanInfo.endtimeName }}</span>
                                </div>
                            </div>
                        </div>
                    </el-col>
                </el-row>
                <!-- 是公司类型的渠道商主账号  parentId ===0 的时候才显示 子账号列表-->
                <div v-if="chanInfo.parentId === 0" class="table-wrap">
                    <div class="flex-wrap a-center">
                        <span class="title">账号信息</span>
                        <el-button @click="openDialog" type="primary" size="mini">开通子账号</el-button>
                    </div>
                    <el-table :data="channelAccountList">
                        <el-table-column label="姓名" prop="name" min-width="100"></el-table-column>
                        <el-table-column label="账号" prop="account" min-width="120"></el-table-column>
                        <el-table-column label="状态" prop="state" min-width="100">
                            <template slot-scope="scope">
                                <div class="status" :class="scope.row.state == 1 ? 'fine' : 'fail'">
                                    {{ scope.row.state === 1 ? '正常' : '作废' }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column label="操作" fixed="right" min-width="120">
                            <template slot-scope="scope">
                                <div class="operator-cell">
                                    <el-button type="text" @click.stop="handleUpdate(scope.row)">修改密码</el-button>
                                    <el-button type="text" @click.stop="handleDelete(scope.row)">作废</el-button>
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </div>
            <!-- <list-foot-bar :checked-num="checkedlist.length">
                <el-pagination
                    layout="total, sizes, prev, pager, next, jumper"
                    :current-page.sync="SP.pageNum"
                    :page-size="SP.pageSize"
                    :total="total"
                    @size-change="handlePageSizeChange"
                    @current-change="handlePageNoChange"
                ></el-pagination>
            </list-foot-bar> -->
        </div>
        <!-- 新增账号 -->
        <el-dialog class="circulation-money" :visible="showDia" @close="showDia = false" title="开通账号" width="560px">
            <el-form :model="accountForm" :rules="rules" ref="accountForm" label-width="120px" class="demo-ruleForm">
                <el-form-item label="姓名" prop="name">
                    <el-input v-model="accountForm.name"></el-input>
                </el-form-item>
                <el-form-item label="手机号(账号)" prop="account">
                    <el-input v-model="accountForm.account"></el-input>
                </el-form-item>
                <el-form-item label="密码" prop="pwd">
                    <el-input v-model="accountForm.pwd"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer">
                <el-button @click="submitAdd" type="primary">保存</el-button>
                <el-button @click="showDia = false">取消</el-button>
            </div>
        </el-dialog>
        <!-- 修改密码 -->
        <el-dialog class="link-account" :visible="updateDia" @close="updateDia = false" title="修改密码" width="560px">
            <el-form
                :model="passwordForm"
                :rules="passwordRules"
                ref="passwordForm"
                label-width="120px"
                class="demo-ruleForm"
            >
                <el-form-item label="新密码" prop="password">
                    <el-input autocomplete="new-password" v-model="passwordForm.password"></el-input>
                </el-form-item>
                <el-form-item label="确认新密码" prop="passwordOk">
                    <el-input autocomplete="new-password" type="password" v-model="passwordForm.passwordOk"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer">
                <el-button @click="submitUpdate" type="primary">确认修改</el-button>
                <el-button @click="updateDia = false">取消</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import forList from '@/mixin/forList';
    import { MD5 } from 'crypto-js';
    export default {
        name: 'index',
        mixins: [forList],
        data() {
            const passwordCheck = (rule, value, callback) => {
                if (!value) {
                    return callback(new Error('请输入密码'));
                }

                const regex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,20}$/;
                if (!regex.test(value)) {
                    callback(new Error('请输入6-20位的数字与字母的组合'));
                } else {
                    callback();
                }
            };
            const phoneCheck = (rule, value, callback) => {
                if (!value) {
                    return callback(new Error('请输入手机号'));
                }
                const regex = /^1[3,4,5,6,7,8,9][0-9]{9}$/;
                if (!regex.test(value)) {
                    callback(new Error('请输入正确的手机号'));
                } else {
                    callback();
                }
            };
            return {
                showDia: false,
                chanInfo: {}, //
                channelAccountList: [],
                accountForm: {
                    name: '',
                    account: '',
                    pwd: '',
                },
                rules: {
                    name: [{ required: true, message: '请输入账号姓名', trigger: 'blur' }],
                    account: [
                        { required: true, message: '请输入手机号码', trigger: 'blur' },
                        { validator: phoneCheck, trigger: 'blur' },
                    ],
                    pwd: [
                        { required: true, message: '请输入密码', trigger: 'blur' },
                        { validator: passwordCheck, trigger: 'blur' },
                    ],
                },
                updateDia: false,
                currAccountId: null,
                passwordForm: {
                    password: '',
                    passwordOk: '',
                },
                passwordRules: {
                    password: [
                        { required: true, message: '请输入密码', trigger: 'blur' },
                        { validator: passwordCheck, trigger: 'blur' },
                    ],
                    passwordOk: [{ required: true, message: '请输入确认密码', trigger: 'blur' }],
                },
            };
        },
        created() {
            this.fetchData();
        },
        methods: {
            fetchData() {
                //
                console.log('fetchData2222222222222', this.channelUserInfo);
                this.$oxios({
                    url: '/api/channelAccount/queryChanInfo',
                    method: 'post',
                    data: {
                        accountId: this.channelUserInfo.accountId,
                    },
                }).then(res => {
                    console.log('fetchData3333', res);
                    if (res.code === 2000) {
                        this.chanInfo = res.data.chanInfo;
                        if (this.chanInfo.parentId === 0) this.fetchsonAccountList();
                    }
                });
            },

            fetchsonAccountList() {
                // /api/channelAccount/queryChannelAccount
                this.$oxios({
                    url: '/api/channelAccount/queryChannelAccount',
                    method: 'post',
                    data: {
                        parentId: this.chanInfo.accountId,
                    },
                }).then(res => {
                    console.log('fetchData3333', res);
                    if (res.code === 2000) {
                        this.channelAccountList = res.data.channelAccountList;
                    }
                });
            },
            handleUpdate(data) {
                this.updateDia = true;
                this.$nextTick(() => {
                    this.currAccountId = data.id;
                    this.$refs.passwordForm.resetFields();
                });
                console.log(data);
            },
            handleDelete(data) {
                console.log(data);
                this.$confirm('此操作将账号作废不可登录，确认作废?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                })
                    .then(() => {
                        this.$oxios({
                            url: '/api/channelAccount/deleteChannelAccount',
                            method: 'post',
                            data: {
                                accountId: data.id,
                            },
                        }).then(res => {
                            if (res.code === 2000) {
                                this.$message({
                                    type: 'success',
                                    message: '作废此账号成功!',
                                });
                                this.fetchsonAccountList();
                            } else {
                                this.$message({
                                    type: 'warning',
                                    message: res.msg,
                                });
                            }
                        });
                    })
                    .catch(() => {});
            },
            openDialog() {
                this.showDia = true;
                this.$nextTick(() => {
                    this.$refs.accountForm.resetFields();
                });
            },
            submitAdd() {
                this.$refs.accountForm.validate(valid => {
                    if (valid) {
                        this.$oxios({
                            url: '/api/channelAccount/saveChannelAccount',
                            method: 'post',
                            data: {
                                ...this.accountForm,
                                pwd: MD5(this.accountForm.pwd).toString(),
                                channelid: this.chanInfo.id,
                                parentid: this.chanInfo.accountId,
                            },
                        }).then(res => {
                            this.$message({
                                type: 'success',
                                message: '添加成功!',
                            });
                            this.showDia = false;
                            this.fetchsonAccountList();
                        });
                    } else {
                        return false;
                    }
                });
            },
            submitUpdate() {
                this.$refs.passwordForm.validate(valid => {
                    if (valid) {
                        if (this.passwordForm.password !== this.passwordForm.passwordOk) {
                            this.$message.error('两次密码不一致');
                            return;
                        }
                        // /api/channelAccount/updatePwd
                        this.$oxios({
                            url: '/api/channelAccount/updatePwd',
                            method: 'post',
                            data: {
                                accountId: this.currAccountId,
                                id: MD5(this.passwordForm.password).toString(),
                            },
                        }).then(res => {
                            this.$message.success('修改成功');
                            this.updateDia = false;
                        });
                    } else {
                        return false;
                    }
                });
            },
        },
    };
</script>

<style lang="scss" scoped>
    .index-wrap {
        padding: 16px;
        background-color: #eaf3ff;
        .scroll-wrapper {
            overflow-x: hidden;
        }
        .index-row {
            margin-bottom: 16px;
            box-sizing: border-box;
            overflow-x: hidden;
            .item-box {
                width: 100%;
                background: rgba(255, 255, 255, 0.2);
                border-radius: 8px;
                border: 2px solid #ffffff;
                padding: 16px;
                .item-box-title {
                    font-weight: 600;
                    font-size: 16px;
                    color: #303133;
                    line-height: 22px;
                    margin-bottom: 16px;
                }
                .item-box-content {
                    background: #ffffff;
                    border-radius: 8px;
                    width: 100%;
                    box-sizing: border-box;
                    padding: 16px;
                    .item-list {
                        display: flex;
                        & ~ .item-list {
                            margin-top: 16px;
                        }
                        .label,
                        .value {
                            font-weight: 400;
                            font-size: 14px;
                            color: #606266;
                            line-height: 20px;
                        }
                    }
                }
            }
        }
        .table-wrap {
            background-color: #ffffff;
            border-radius: 8px;
            padding: 16px;
            .title {
                font-weight: 500;
                font-size: 16px;
                color: #303133;
                line-height: 22px;
                margin-right: 8px;
            }
            .status {
                width: 53px;
                height: 24px;
                text-align: center;
                line-height: 24px;
                font-weight: 400;
                font-size: 14px;

                border-radius: 4px;
                &.fine {
                    background: rgba(76, 179, 93, 0.1);
                    color: #4cb35d;
                }
                &.fail {
                    background: rgba(144, 147, 153, 0.1);
                    color: #909399;
                }
            }
        }
    }
</style>
