<template>
    <div class="module-wrapper">
        <router-view></router-view>
    </div>
</template>

<script>
    export default {
        name: 'channel',
        props: {},
        data() {
            return {};
        },
        computed: {},
        methods: {},
    };
</script>

<style lang="scss" scoped></style>
