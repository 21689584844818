import Vue from 'vue';
import VueRouter from 'vue-router';
Vue.use(VueRouter);

import { routeList } from './menu';
const routes = routeList;

const router = new VueRouter({
    // base: "/JDGJ",
    routes,
});

router.beforeEach((to, from, next) => {
    let token = localStorage.getItem('token');
    console.log('222', token);
    // next()
    // return
    if (token) {
        // 判断是否登录
        next();
    } else {
        // 未登录跳转登录
        if (to.path == '/login' || to.path == '/channelLogin') {
            next();
        } else {
            next({ path: '/login' });
        }
    }
});

router.afterEach(to => {
    // 设置页面标题
    document.title = to.meta.title;
});

export default router;
