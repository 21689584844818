import { render, staticRenderFns } from "./calculateCustomerList.vue?vue&type=template&id=f653756e&scoped=true"
import script from "./calculateCustomerList.vue?vue&type=script&lang=js"
export * from "./calculateCustomerList.vue?vue&type=script&lang=js"
import style0 from "./calculateCustomerList.vue?vue&type=style&index=0&id=f653756e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f653756e",
  null
  
)

export default component.exports