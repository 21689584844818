/**
 * 这个文件定义了列表页的混入代码：
 * 1、data.SP    保存了当前页面的查询参数
 *    data.total 保存了当前列表的总条数
 *    ...
 * 2、计算属性 totalPage 保存当前列表总页数
 * 
        后面再写说明
        updateSPtoSP_
        updateSP_toSP
        clearSP_
        productDateStr
        extractParams
        handleAdvancedSearchBtn
        closeAdvanceSearch
        handleSearch
        handleClearBtn
        handlePageSizeChange
        handlePageNoChange
        handleSelectionChange

 * 3、handlePageSizeChange 和 handlePageNoChange 两个方法处理
 *    分页组件的事件
 * 4、handleAdvancedSearchBtn方法是 高级搜索 按钮的点击事件处理程序
 * 5、handleExportBtn方法是 导出 按钮的点击事件处理程序
 * 6、列表组件创建后，默认调用 fetchData 方法获取页面数据
 * 
 * 5、6 的方法需要中在列表页组件中具体实现，这个混入一个空的方法是
 *    为了防止在页面模板中复制了其他页面但是找不到方法二报错
 */

// 处理日期时间
// import moment from "moment";
import clearListPageStateOnCreated from './clearListPageStateOnCreated';

export default {
    data() {
        return {
            SP: {
                // SP: URLSearchParams 表示列表查询参数
                pageSize: 10,
                pageNum: 1,
                search_sys: 0,
            },
            SP_: {}, // 点击搜索按钮前，输入框里输入的查询参数
            total: 0, // 数据总条数

            tableData: [], // 表格数据
            checkedlist: [], // 选中的数据
            loading: false, // 当前状态
            showAdvanceSearch: false, // 高级搜索状态

            auth: {
                save: false, // 保存权限
                detail: false, // 获取详情权限
                edit: false, // 编辑权限
                delete: false, // 删除权限
                export: false, // 导出权限
            },
        };
    },

    computed: {
        totalPage() {
            return Math.ceil(this.total / this.SP.pageSize);
        },
    },

    methods: {
        // 同步 SP 到 SP_
        updateSPtoSP_() {
            for (let key in this.SP) {
                switch (key) {
                    case 'pageSize':
                    case 'pageNum':
                        break;

                    default:
                        this.SP_[key] = this.SP[key];
                        break;
                }
            }
        },

        // 同步 SP_ 到 SP
        updateSP_toSP() {
            for (let key in this.SP_) {
                switch (key) {
                    case 'pageSize':
                    case 'pageNum':
                        break;

                    default:
                        this.SP[key] = this.SP_[key];
                        break;
                }
            }
        },

        // 清空 SP_: 将高级搜索的字段恢复为默认值（见 data.SP_）
        clearSP_() {
            for (let key in this.SP_) {
                const oVal = this.SP_[key];
                switch (key) {
                    case 'pageSize':
                    case 'pageNum':
                    case 'search_sys':
                        break;

                    case 'searchType':
                    case 'fuzzyQueryType':
                        this.SP_[key] = 0;
                        break;

                    default:
                        if (oVal.constructor === Array) this.SP_[key] = [];
                        else if (oVal.constructor === Object) this.SP_[key] = {};
                        else this.SP_[key] = '';
                        break;
                }
            }
            this.clearSP_trim?.();
        },

        // 获取日期对象的 YYYY-MM-DD 格式
        productDateStr(str = '') {
            // return str && moment(str).format("YYYY-MM-DD")
            const date = new Date(str);
            const YYYY = date.getFullYear();
            const MM = String(date.getMonth() + 1).padStart(2, '0');
            const DD = String(date.getDate()).padStart(2, '0');
            return `${YYYY}-${MM}-${DD}`;
        },

        // 提取干净的查询参数: 剔除空的字段，防止 get 查询参数太长超上限
        extractParams(forExport) {
            const stateData = forExport || this.showAdvanceSearch ? this.SP_ : this.SP;
            const params = {};
            for (let key in stateData) {
                let _val = stateData[key];
                let valFine = true;
                switch (key) {
                    // case "searchType":
                    case 'search_type':
                    case 'fuzzyQueryType':
                        // 如果没有输入关键字，搜索类型页不传
                        if (!(stateData.searchValue || stateData.fuzzyQueryValue || stateData.search_value))
                            valFine = false;
                        break;

                    case 'search_sys':
                        if (!_val) valFine = false;
                        break;

                    default:
                        // 列表转换为字符串
                        if (_val instanceof Array) _val = _val.join();
                        // 无效的字段不传
                        if (_val == null || _val === '') valFine = false;
                        break;
                }
                valFine && (params[key] = _val);
            }

            if (forExport) {
                delete params.pageNum;
                delete params.pageSize;
            }

            return params;
        },

        // 点击 高级搜索
        handleAdvancedSearchBtn() {
            // console.warn("当前列表组件没有定义 handleAdvancedSearchBtn 高级搜索按钮的事件处理方法")
            this.showAdvanceSearch = true;
            if (this.updateSPtoSP_) this.updateSPtoSP_();
        },

        // 关闭高级搜索
        closeAdvanceSearch() {
            this.showAdvanceSearch = false;
        },

        // 点击搜索
        handleSearch() {
            // console.log('搜索', this.SP_);
            // 点击面板上的搜索，才会将面板搜索条件转为真正的搜索条件
            // if (this.showAdvanceSearch) this.updateSP_toSP();
            this.updateSP_toSP();
            this.SP.pageNum = 1;
            this.closeAdvanceSearch(); // 关闭高级搜索面板
            this.savePageState(); // 保存列表状态
            this.fetchData();
        },

        // 在搜索栏的输入框按回车键
        handleInputEnter(e) {
            // console.log("输入框键入了回车键", e.currentTarget)
            // e.currentTarget.querySelector("input").blur();
            e.target.blur();
            this.handleSearch();
        },

        // 点击清空
        handleClearBtn() {
            this.clearSP_();
            this.handleSearch();
            // this.updateSP_toSP();
            // this.closeAdvanceSearch();
            // this.fetchData();
        },

        // 分页组件发生 每页数据条数 改变事件
        handlePageSizeChange(e) {
            // console.log("每页数据条数改变", e);
            this.SP.pageSize = e;
            this.SP.pageNum = 1;
            // 用当前查询数据覆盖查询字段的填写内容,避免用户填写后
            // 没有点击搜索而出现状态错位的bug
            if (this.updateSPtoSP_) this.updateSPtoSP_();
            this.savePageState(); // 保存列表状态
            this.fetchData();
        },

        // 分页组件发生 当前页 改变事件
        handlePageNoChange(e) {
            // console.log("当前页改变", e);
            this.SP.pageNum = e;
            // 用当前查询数据覆盖查询字段的填写内容,避免用户填写后
            // 没有点击搜索而出现状态错位的bug
            if (this.updateSPtoSP_) this.updateSPtoSP_();
            this.savePageState(); // 保存列表状态
            this.fetchData();
        },

        // el-table 选中事件，更新 data
        handleSelectionChange(e) {
            this.checkedlist.splice(0, this.checkedlist.length, ...e);
        },

        // 点击 导出
        handleExportBtn() {
            console.warn('当前列表组件没有定义 handleExportBtn 导出按钮的事件处理方法');
        },

        // 获取页面数据
        fetchData() {
            console.warn('当前列表组件没有定义 fetchData 方法');
        },

        // 保存列表状态
        savePageState() {
            // console.log("保存列表状态")
            const { noSaveState, name } = this.$options;
            if (!noSaveState) {
                this.$store.commit('saveListPageState', {
                    name: name,
                    params: this.SP,
                });
            }
        },
    },

    created() {
        clearListPageStateOnCreated.call(this);
    },

    watch: {
        'SP.search_sys'(val) {
            this.handlePageNoChange(this.SP.pageNum);
            if (this.watchSystemChange) {
                this.watchSystemChange();
            }
        },
    },
};
