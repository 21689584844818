<template>
    <div class="system-roles list-page page">
        <div class="page--list" style="background: #fff">
            <div class="scroll-wrapper pd4 set--bottom-foot-padding">
                <el-row style="padding: 16px 16px 0 16px; background: #f4f7fa" class="flex">
                    <div style="margin-right: 32px; margin-bottom: 16px">
                        <span class="demonstration">发起系统：</span>
                        <el-select clearable v-model="searchSysCode" placeholder="请选择活动区域">
                            <el-option
                                v-for="level in systemEnumarr"
                                :key="level.id"
                                :label="level.name"
                                :value="level.sysCode"
                            ></el-option>
                        </el-select>
                    </div>
                    <div style="margin-right: 32px; margin-bottom: 16px">
                        <span class="demonstration">操作时间：</span>
                        <el-date-picker
                            :type="datetype"
                            class="width2"
                            v-model="searchStartTime"
                            :value-format="valueformat"
                            placeholder="请选择"
                        ></el-date-picker>
                        <span> - </span>
                        <el-date-picker
                            :type="datetype"
                            class="width1h"
                            v-model="searchEndTime"
                            :value-format="valueformat"
                            placeholder="请选择"
                        ></el-date-picker>
                    </div>

                    <div style="margin-bottom: 16px">
                        <el-button style="" type="primary" @click.stop="handleSubmit">搜索</el-button>
                        <el-button @click.stop="handleClear">清空</el-button>
                    </div>
                </el-row>
                <el-table :data="tableData" style="width: 100%">
                    <el-table-column type="expand">
                        <template slot-scope="scope">
                            <el-row :gutter="20">
                                <el-col :span="12">
                                    <div>
                                        <json-viewer
                                            :value="JSON.parse(scope.row.startConetnt)"
                                            :expand-depth="5"
                                            boxed
                                            sort
                                            :show-array-index="false"
                                            copyable
                                        >
                                            <template slot="copy">
                                                <i class="el-icon-document-copy" title="复制"></i>
                                            </template>
                                        </json-viewer>
                                    </div>
                                </el-col>
                                <el-col :span="12">
                                    <div>
                                        <json-viewer
                                            :value="JSON.parse(scope.row.endContent)"
                                            :expand-depth="5"
                                            boxed
                                            sort
                                            :show-array-index="false"
                                            copyable
                                        >
                                            <template slot="copy">
                                                <i class="el-icon-document-copy" title="复制"></i>
                                            </template>
                                        </json-viewer>
                                    </div>
                                </el-col>
                            </el-row>
                        </template>
                    </el-table-column>
                    <el-table-column
                        label-class-name="label-bg-EBEDF0"
                        prop="createTimeName"
                        label="操作时间"
                        width="180"
                    >
                    </el-table-column>
                    <el-table-column
                        label-class-name="label-bg-EBEDF0"
                        prop="startSysCodeName"
                        label="发起系统"
                        width="180"
                    >
                    </el-table-column>
                    <el-table-column label-class-name="label-bg-EBEDF0" prop="startConetnt" label="消息内容">
                        <template slot-scope="scope">
                            <div class="eill-2">
                                {{ scope.row.startConetnt }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column
                        align="center"
                        class-name="clonum-bg-7FBBFF"
                        label-class-name="label-bg-7FBBFF"
                        label=""
                    >
                        <template slot="header">
                            <img class="arrow1" src="../../../assets/images/1.png" alt="" />
                        </template>
                        <template slot-scope="scope">
                            <div class="eill-2">
                                <img :src="scope.row.state == 1 ? rightIcon : errorIcon" alt="" />
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label-class-name="label-bg-2979FF" prop="endTimeName" label="接收时间" width="180">
                    </el-table-column>
                    <el-table-column
                        label-class-name="label-bg-2979FF"
                        prop="endSysCodeName"
                        label="接收系统"
                        width="180"
                    >
                    </el-table-column>
                    <el-table-column label-class-name="label-bg-2979FF" prop="endContent" label="返回内容">
                        <template slot-scope="scope">
                            <div class="eill-2">
                                {{ scope.row.endContent }}
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
            </div>

            <list-foot-bar>
                <el-pagination
                    layout="total, sizes, prev, pager, next, jumper"
                    :current-page.sync="pageNum"
                    :page-size="pageSize"
                    :total="total"
                    @size-change="handlePageSizeChange"
                    @current-change="handlePageNoChange"
                ></el-pagination>
            </list-foot-bar>
        </div>
    </div>
</template>

<script>
    import JsonViewer from 'vue-json-viewer';
    export default {
        components: { JsonViewer },
        data() {
            return {
                pageNum: 1,
                pageSize: 10,
                total: 0,
                rightIcon: require('../../../assets/images/right.png'),
                errorIcon: require('../../../assets/images/error.png'),

                searchSysCode: '',
                datetype: 'date',
                valueformat: 'yyyy-MM-dd',
                searchStartTime: '',
                searchEndTime: '',
                tableData: [],
                systemEnumarr: [
                    {
                        id: 1,
                        name: '移民',
                        sysCode: 'AYM',
                    },
                    {
                        id: 2,
                        name: '房产',
                        sysCode: 'CRM',
                    },
                    {
                        id: 3,
                        name: '债券',
                        sysCode: 'MON',
                    },
                ],
            };
        },

        created() {
            this.fetchData();
        },
        methods: {
            handleSubmit() {
                this.fetchData();
            },
            handleClear() {
                this.searchSysCode = '';
                this.searchStartTime = '';
                this.searchEndTime = '';
                this.pageNum = 1;
                this.fetchData();
            },
            // 翻页相关
            handlePageSizeChange(size) {
                this.pageSize = size;
                this.fetchData();
            },
            // 翻页相关
            handlePageNoChange(no) {
                this.pageNum = no;
                this.fetchData();
            },
            async fetchData() {
                const loading = this.$loading();
                const res = await this.$axios({
                    url: '/api/toolApi/queryCenTransferList',
                    method: 'post',
                    data: {
                        searchSysCode: this.searchSysCode,
                        pageNum: this.pageNum,
                        pageSize: this.pageSize,
                        searchStartTime: this.searchStartTime,
                        searchEndTime: this.searchEndTime,
                    },
                });
                loading.close();
                if (res.code === 2000) {
                    this.tableData = res.data.cenTransferList || [];

                    this.total = res.data.count || 0;
                } else {
                    this.$message({
                        message: res.msg || '获取数据失败',
                        type: 'warning',
                    });
                }
            },
            toJson(str) {
                return JSON.parse(str);
            },
        },
    };
</script>

<style scoped lang="scss">
    .flex {
        display: flex;
    }
    /deep/ .label-bg-EBEDF0 {
        background: #f4f7fa !important;
        color: #303133;
    }
    /deep/ .label-bg-2979FF {
        background: #2979ff !important;
        color: #fff;
    }
    /deep/ .label-bg-7FBBFF {
        background: #7fbbff !important;
        color: #fff;
    }
    /deep/ .el-table__expand-column.el-table__cell.is-leaf {
        background: #f4f7fa !important;
    }

    .arrow1 {
        height: 100%;
    }
    /deep/ .clonum-bg-7FBBFF {
        background: #f4f7fa;
    }
    .eill-2 {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }
</style>
