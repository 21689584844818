import Vcode from '../../components/verifyImage/index.vue';
import Img1 from '../../assets/images/verify/verify1.jpg';
import Img2 from '../../assets/images/verify/verify2.jpg';
import Img3 from '../../assets/images/verify/verify3.jpg';
import Img4 from '../../assets/images/verify/verify4.jpg';
import Img5 from '../../assets/images/verify/verify5.jpg';
import { MD5 } from 'crypto-js';
export default {
    components: { Vcode },
    data() {
        const phoneCheck = (rule, value, callback) => {
            if (!value) {
                return callback(new Error('请输入手机号'));
            }
            const regex = /^1[3,4,5,6,7,8,9][0-9]{9}$/;
            if (!regex.test(value)) {
                callback(new Error('请输入正确的手机号'));
            } else {
                callback();
            }
        };
        const verCodeCheck = (rule, value, callback) => {
            if (!value) {
                return callback(new Error('请输入验证码'));
            }
            callback();
        };
        return {
            loginForm: {
                account: '',
                password: '',
                verCode: '',
            },
            loginRules: {
                account: [
                    {
                        required: true,
                        message: '请输入登录账号',
                        trigger: 'blur',
                    },
                ],
                password: [{ required: true, message: '请输入登录密码', trigger: 'blur' }],
            },

            codeimgs: [Img1, Img2, Img3, Img4, Img5],
            isShow: false,
            showDia: false,
            passwordForm: {
                account: '',
                verCode: '',
            },
            passwordFormRules: {
                account: [
                    { required: true, message: '请输入登录账号', trigger: 'blur' },
                    { validator: phoneCheck, trigger: 'blur' },
                ],
                verCode: [{ validator: verCodeCheck, message: '请输入验证码', trigger: 'blur' }],
            },
            timeNum: null,
            timeInterval: null,
        };
    },
    methods: {
        submitForm() {
            this.$refs.loginForm.validate(valid => {
                if (valid) {
                    this.$oxios({
                        url: '/api/channelAccount/queryVerCode',
                        method: 'post',
                        data: {
                            account: this.loginForm.account,
                            type: 0,
                        },
                    }).then(res => {
                        if (res.code === 2000) {
                            this.loginForm.verCode = res.data.verCode;
                            this.isShow = true;
                        } else {
                            this.$message({
                                message: res.msg,
                                type: 'error',
                            });
                        }
                    });
                } else {
                    return false;
                }
            });
        },
        onSuccess() {
            this.$oxios({
                url: '/api/channelAccount/loginChannel',
                method: 'post',
                data: {
                    account: this.loginForm.account,
                    // pwd: MD5(this.loginForm.password).toString(),
                    pwd: this.loginForm.password,
                    verCode: this.loginForm.verCode,
                },
            })

                .then(res => {
                    if (res.code === 2000) {
                        this.$store.dispatch('storeChannelUser', res);
                        this.$router.replace({ path: '/home' });
                    } else {
                        this.$message({
                            message: res.msg || '登录失败，请稍后重试',
                            type: 'error',
                        });
                        // this.resetValidateStyle();
                    }
                })

                .catch(reason => {
                    this.$message({
                        message: '登录失败，请稍后重试',
                        type: 'error',
                    });
                    console.warn('登录程序出错', reason);
                    // this.resetValidateStyle();
                    // this.loaded();
                })
                .then(() => {
                    this.isShow = false;
                });
        },
        handelReset() {
            this.showDia = true;
            this.$nextTick(() => {
                this.$refs.passwordForm.resetFields();
                this.timeNum = null;
                clearInterval(this.timeInterval);
            });
        },
        //
        getVercode() {
            // this.timeNum = 60;
            // this.timeInterval = setInterval(() => {
            //     this.timeNum--;
            //     if (this.timeNum == 0) {
            //         clearInterval(this.timeInterval);
            //     }
            // }, 1000);

            // return;

            const regex = /^1[3,4,5,6,7,8,9][0-9]{9}$/;
            if (this.passwordForm.account && regex.test(this.passwordForm.account)) {
                this.$oxios({
                    url: '/api/channelAccount/queryVerCode',
                    method: 'post',
                    data: {
                        account: this.passwordForm.account,
                        type: 1,
                    },
                }).then(res => {
                    if (res.code === 2000) {
                        this.timeNum = 60;
                        this.timeInterval = setInterval(() => {
                            this.timeNum--;
                            if (this.timeNum == 0) {
                                clearInterval(this.timeInterval);
                            }
                        }, 1000);
                    } else {
                        this.$message({
                            message: res.msg,
                            type: 'error',
                        });
                    }
                });
            } else {
                this.$message.error('请输入正确的账号');
            }
        },
        submitUpdate() {
            console.log('11111111', this.passwordForm);
            this.$refs.passwordForm.validate(valid => {
                console.log('valid', valid);
                if (valid) {
                    // this.$axios.post('/api/channelAccount/resetAccountPwd', this.loginForm).then(res => {
                    //     this.submitUpdateLoading = false;
                    //     if (res.data.code == 200) {
                    //         clearInterval(this.timeInterval);
                    //     }
                    // });
                    console.log('2222222', this.passwordForm);
                    this.$oxios({
                        url: '/api/channelAccount/resetAccountPwd',
                        method: 'post',
                        data: {
                            account: this.passwordForm.account,
                            verCode: this.passwordForm.verCode,
                        },
                    }).then(res => {
                        if (res.code === 2000) {
                            clearInterval(this.timeInterval);
                            this.showDia = false;
                            this.timeNum = 0;
                            // this.timeInterval = setInterval(() => {
                            //     this.timeNum--;
                            //     if (this.timeNum == 0) {
                            //
                            //     }
                            // }, 1000);
                        } else {
                            this.$message({
                                message: res.msg,
                                type: 'error',
                            });
                        }
                    });
                } else {
                    return false;
                }
            });
        },
        handelBack() {
            this.$router.push({
                name: 'Login',
            });
        },
    },
};
