import layout from '../views/_0_main/layout/layout.vue'; // 布局框架
import login from '../views/_0_main/login/login.vue'; // 登录组件
import home from '../views/_0_main/home/home.vue'; // 首页组件
import basic from './module_1_basic'; // 基础设置模块的路由
import order from './module_2_staff'; // 订单管理模块的路由
import cus from './module_3_cus'; // 客户模块的路由
import tool from './module_4_tool'; // 小工具模块的路由
import channel from './channel';
import channelLogin from '../views/channel/login.vue';

const options = [
    {
        path: '',
        name: 'Main',
        title: '首页',
        component: layout,
        redirect: 'home',
        children: [
            {
                path: '/home',
                name: 'Home',
                component: home,
                title: '主页',
                descreption: '返回首页',
                icon: 'home',
            },
            {
                path: '/setting',
                name: 'UpdatePwd',
                component: () => import('../views/_0_main/updatePwd/updatePwd.vue'),
                title: '个人中心',
                awayMenu: true,
            },

            basic, // 基础设置
            order, // 订单管理
            cus,
            tool, // 小工具
            channel, // 渠道商
        ],
    },
    {
        path: '/login',
        name: 'Login',
        component: login,
        title: '登录',
        icon: '',
        awayMenu: true,
    },
    {
        path: '/channelLogin',
        name: 'channelLogin',
        component: channelLogin,
        title: '登录',
        icon: '',
        awayMenu: true,
    },
];

/**
 * routeList，用于在 router 中生成 routes
 */
function extractRouteOption(item) {
    // const { path, name, component, title } = item;
    if (item.children && item.children.length) {
        item.children = item.children.map(extractRouteOption);
    }
    item.meta = { title: item.title };
    return item;
}

const routeList = options.map(extractRouteOption);

/**
 * menuList，用于再 App 中导入，并渲染左侧导航目录
 */
function extractMenuProps(_item) {
    const { path, title, icon } = _item;
    const item = { path, title, iconClass: icon };

    if (_item.children && _item.children.length && !_item.awayMenu) {
        item.children = _item.children.filter(it => !it.awayMenu).map(extractMenuProps);
    }

    return item;
}

const menuList = options[0].children.filter(item => !item.awayMenu).map(extractMenuProps);

export { routeList, menuList };
export default options;
