import { render, staticRenderFns } from "./home.vue?vue&type=template&id=c27e60c8&scoped=true"
import script from "./home?vue&type=script&lang=js&external"
export * from "./home?vue&type=script&lang=js&external"
import style0 from "./home.vue?vue&type=style&index=0&id=c27e60c8&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c27e60c8",
  null
  
)

export default component.exports